import { AspectImage, Flex, Box, Image } from "@theme-ui/components";
import P1 from "../../../../src/images/Alte20Leipziger.gif";
import P2 from "../../../../src/images/Gothaer.gif";
import P3 from "../../../../src/images/RV.gif";
import P4 from "../../../../src/images/Axa.gif";
import P5 from "../../../../src/images/NC3BCrnberger.gif";
import P6 from "../../../../src/images/Inter.gif";
import P7 from "../../../../src/images/Barmenia.gif";
import P8 from "../../../../src/images/Alte20Oldenburger.gif";
import P9 from "../../../../src/images/Universa.gif";
import P10 from "../../../../src/images/DKV.gif";
import * as React from 'react';
export default {
  AspectImage,
  Flex,
  Box,
  Image,
  P1,
  P2,
  P3,
  P4,
  P5,
  P6,
  P7,
  P8,
  P9,
  P10,
  React
};