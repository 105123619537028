// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-posts-datenshutz-mdx": () => import("./../../../src/posts/datenshutz.mdx" /* webpackChunkName: "component---src-posts-datenshutz-mdx" */),
  "component---src-posts-felder-mdx": () => import("./../../../src/posts/felder.mdx" /* webpackChunkName: "component---src-posts-felder-mdx" */),
  "component---src-posts-impressum-mdx": () => import("./../../../src/posts/impressum.mdx" /* webpackChunkName: "component---src-posts-impressum-mdx" */),
  "component---src-posts-nachhaltigkeit-mdx": () => import("./../../../src/posts/nachhaltigkeit.mdx" /* webpackChunkName: "component---src-posts-nachhaltigkeit-mdx" */)
}

